import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'stretchy-input',
    templateUrl: './stretchy-input.component.html',
    styleUrls: ['./stretchy-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StretchyInputComponent),
            multi: true
        }
    ],
    standalone: false
})
export class StretchyInputComponent implements OnChanges, AfterViewInit, ControlValueAccessor {
  public isEditingInput = false;
  public defaultInputValue: string;

  @Input() ngModel: string;
  @Output() ngModelChange = new EventEmitter<string>();

  private onChange = (value: string) => {};
  private onTouched = () => {};
  inputControl = new FormControl('');

  constructor(private elementRef: ElementRef) { }

  _getStretchyElement() {
    return this.elementRef.nativeElement.querySelector('div.input-stretchy') as HTMLDivElement;
  }

  ngAfterViewInit(): void {
    this.inputControl.disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    // resize input if model was externally modified/updated
    if (changes && !this.isEditingInput) {
      this.defaultInputValue = this.ngModel;
      this.inputControl.setValue(this.ngModel);
      this.inputControl.disable();
    }
  }

  _setFocus(elem: HTMLElement) {
    if (!elem) return;
    elem.focus();
  }

  setEditable() {
    if (this.isEditingInput) {
      return;
    }

    let stretchyInputElement = this._getStretchyElement();
    setTimeout(this._setFocus.bind(this, stretchyInputElement));
    this.inputControl.enable();
    this.isEditingInput = true;
  }

  _setNonEditable() {
    if (!this.isEditingInput) {
      return;
    }
    let nameVal = this.inputControl.value.replace(/\s/g, '');

    if (!nameVal) {
      // user deleted entire name, set the name and width values to the defaults
      this.ngModel = this.defaultInputValue;
      this.inputControl.setValue(this.ngModel);
      this.ngModelChange.emit(this.ngModel);
      this.onChange(this.ngModel);
    } else {
      this.ngModel = this.inputControl.value;
      this.onChange(this.ngModel);
    }
    this.isEditingInput = false;
    this.onTouched();
    this.inputControl.disable();
  }

  onInputBlur() {
    this._setNonEditable();
  }

  inputKeyDown(keyEvent: KeyboardEvent) {
    // handle enter key
    if (keyEvent.key === 'Enter' && this.isEditingInput) {
      this._setNonEditable();
      keyEvent.preventDefault();
    }
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.ngModel = value;
      this.defaultInputValue = value;
    } else {
      this.ngModel = '';
      this.defaultInputValue = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.inputControl[isDisabled ? 'disable' : 'enable']();
  }
}