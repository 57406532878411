<form #userForm="ngForm" id="forms.userForm" role="form" name="forms.userForm" autocomplete="on" (submit)="save()" novalidate>
  <div rvSpinner="user-settings" [rvShowSpinner]="loading" class="settings-page-container">
    <div class="settings-page-body">
      <h3>Add User</h3>
      <div id="user-settings" class="settings-page-section">
        <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
          <strong>{{formError}}</strong> {{apiError}}
        </div>

        <div class="form-group required"
          [ngClass]="{ 'has-error' : username.invalid && (!username.pristine || userForm.submitted) }">
          <label>
            Username:
          </label>
          <input #username="ngModel" id="user-settings-username"
            type="email" required name="username"
            class="form-control"
            [(ngModel)]="user.username"
            [pattern]="EMAIL_REGEX"
            />
            <p [shown]="username.invalid && (!username.pristine || userForm.submitted)"
              class="help-block validation-error-message-email">User name must be a valid email address.</p>
        </div>

        <user-fields [user]="user"></user-fields>
      </div>
    </div>
    <div class="settings-page-footer">
      <common-actions
        [actions]="actions"
        [saving]="loading">
      </common-actions>
    </div>
  </div>
</form>