<form #companyForm="ngForm" id="forms.companyForm" role="form" name="forms.companyForm" (submit)="save()" autocomplete="on" novalidate>
  <div class="add-subcompany settings-page-container" rvSpinner="add-subcompany" [rvShowSpinner]="loading">
    <div class="settings-page-body">
      <div class="settings-page-section">
        <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
          <strong>{{formError}}</strong> {{apiError}}
          @if (isAddressError) {
            <suggest-general-delivery [addressObject]="company"></suggest-general-delivery>
          }
        </div>
        <company-fields [company]="company"></company-fields>
      </div>
    </div>
    <div class="settings-page-footer">
      <common-actions
            [actions]="actions"
            [saving]="loading">
      </common-actions>
    </div>
  </div>
</form>
