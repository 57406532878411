<div id="company-users" class="settings-page-container">
  <div class="settings-page-body">

    <!-- Admin View with Tabs -->
    <div *requireRole="'ua'">
      <mat-tab-group #TabGroup [(selectedIndex)]="selectedTabIndex" class="user-tabs tw-w-full tw-border-none">
        <!-- Users Tab -->
        <mat-tab label="Users">
          <div class="w-100 tw-mt-8">
            <div id="errorBox" [shown]="users.apiError" class="alert alert-danger text-danger" role="alert">
              <strong>{{users.errorMessage}}</strong> {{users.apiError}}
            </div>
            <ng-container *ngTemplateOutlet="userListContent"></ng-container>
          </div>
        </mat-tab>

        <!-- SSO Settings Tab -->
        <mat-tab label="SSO Settings">
          <div class="settings-page-section tw-mt-8">
            <sso-settings (cancelEvent)="cancel()"></sso-settings>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <!-- Non-Admin View -->
  <div class="w-100" *requireRole="'!ua'">
    <ng-container *ngTemplateOutlet="userListContent"></ng-container>
  </div>
</div>

<!-- Reusable User List Template -->
<ng-template #userListContent>
  <!-- <div class="settings-page-body"> -->
    <div class="tw-flex tw-flex-col tw-gap-4 xl:!tw-flex-row button-toolbar-md-folded">
      <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="users.doSearch"
      ></search-filter>
      <div class="ml-md-3 button-toolbar tw-flex" *requireRole="'ua sa'">
        <a href="#" *requireRole="'sa'" (click)="exportUsers()"
          class="btn btn-default btn-toolbar rise-admin-only">Export</a>
        <a id="userAddButton" class="btn btn-primary btn-toolbar-wide" uiSref="apps.user.add">
          Add User
        </a>
      </div>
    </div>

    <!-- List of Users -->
    <div class="settings-page-section scrollable-list tw-mt-8 tw-max-h-[calc(100vh-300px)]" scrollingList
      (scrollEndEvent)="users.load()" rvSpinner="company-users-list" [rvShowSpinner]="users.loadingItems">
      <table class="table">
        <thead class="table-header">
          <tr class="table-header__row">
            <th class="table-header__cell u_clickable">Name</th>
          </tr>
        </thead>
        <tbody class="table-body">
          @for (user of users.items.list; track user) {
          <tr tabindex="0" class="table-body__row company-users-list-item"
            (keyup.enter)="goToUserDetails(user.username)" (click)="goToUserDetails(user.username)">
            <td class="table-body__cell u_clickable">
              <p class="list-group-item-text">
                <strong>{{user.firstName}} {{user.lastName}}</strong>
                <small class="text-muted ml-2">{{user.email}}</small>
              </p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  <!-- </div> -->
</ng-template>