<div class="tw-px-6" rvSpinner="display-loader" [rvShowSpinner]="displayService.loadingDisplay">
  <form
    #displayForm="ngForm"
    role="form"
    id="forms.displayForm"
    name="displayDetails"
    (submit)="save()"
    novalidate>
    <common-actions
      [(title)]="displayService.display.name"
      [actions]="actions"
      [saving]="displayService.savingDisplay"
      [changeDate]="displayService.display.changeDate"
      [changedBy]="displayService.display.changedBy">
    </common-actions>
    <div class="tw-pt-8 tw-border-t tw-border-x-0 tw-border-b-0 tw-border-solid tw-border-gray-200">
      <display-fields
        [(selectedSchedule)]="selectedSchedule"
        [(displayControlType)]="displayControlType"
      />
    </div>
  </form>
</div>