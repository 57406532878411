<form #allocationForm="ngForm" (ngSubmit)="save(allocationForm)">
  <div class="settings-page-container">
    <div class="settings-page-body">
      <div class="settings-page-section">
        <div class="border-round flex-row space-between p-4 mb-4">
          <label for="assign-licenses-toggle" class="mb-0 pr-3">Assign Licenses Manually</label>
          <mat-slide-toggle
            id="assign-licenses-toggle"
            name="assignLicensesToggle"
            [(ngModel)]="companyAllocationService.licenseAllocationEnabled">
          </mat-slide-toggle>
        </div>
        @if (errorMessage || companyService.apiError) {
          <div class="alert alert-danger" role="alert">
            {{ errorMessage || companyService.apiError }}
          </div>
        }
        <div class="company-licenses-layout">
          <div class="company-licenses-column">
            <!-- <div class="flex-row pb-2">
              <search-filter class="flex-grow" [filterConfig]="filterConfig" [search]="search" [doSearch]="boundSearch"></search-filter>
              <a class="btn btn-default btn-toolbar-wide ml-4" uiSref="apps.company.add">Add Sub-Company</a>
            </div> -->
            <div class="scrollable-list horizontal-scroll mb-2"
              scrollingList (scrollEndEvent)="companies.load()"
              rvSpinner="company-licenses-list" [rvShowSpinner]="companies.loadingItems || saving">
              <table class="table dense company-licenses-table">
                <thead class="table-header">
                  <tr class="table-header__row">
                    <th class="table-header__cell cell-company-tree">&nbsp;</th>
                    <th class="table-header__cell u_align-middle u_clickable cell-company-name">
                      Company Name
                    </th>
                    <th class="table-header__cell u_align-middle u_clickable cell-license-type">
                      License Type
                    </th>
                    <th class="table-header__cell u_clickable align-center cell-licenses-allocated">
                      Licenses<br>Allocated
                    </th>
                    <th class="table-header__cell u_clickable align-center cell-display-count">
                      Display<br>Count
                    </th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr company-license-item
                    class="table-body__row borderless"
                    [company]="selectedCompany"
                    [root]="true">
                  </tr>
                  @for (company of companyTree; track company; let i = $index) {
                    <tr company-license-item
                      class="table-body__row borderless"
                      [company]="company"
                      [last]="isLast(i)"
                      (selectCompany)="setCompany($event)"
                      [shown]="company.parent === undefined || isSubShown(company.parent)">
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div class="license-totals-column">
            <div class="border-container px-4 py-2 scrollable-list horizontal-scroll">
              <company-license-totals></company-license-totals>
            </div>
            @if (companyAllocationService.getRequiredLicenses() && !currentPlanService.currentPlan.isPurchasedByParent) {
              <div id="billing-alert" class="madero-style alert alert-warning mt-4">
                <i aria-hidden="true" class="fa fa-exclamation-circle icon-left text-warning"></i>
                <strong>Billing Update Required</strong>
                <p>
                  This change requires adding
                  <strong>{{ companyAllocationService.getRequiredLicensesText('enterprise') }}</strong>
                  {{ companyAllocationService.getRequiredLicenses('enterprise') && companyAllocationService.getRequiredLicenses('unlimited') ? ' and ' : '' }}
                  <strong>{{ companyAllocationService.getRequiredLicensesText('unlimited') }}</strong>
                  to your plan.
                </p>
              </div>
            }
            @if (currentPlanService.currentPlan.isPurchasedByParent) {
              <div id="managed-by-alert" class="madero-style alert alert-info mt-4">
                <streamline-icon aria-hidden="true" name="info" width="14" height="14" />
                <strong>This subscription is currently managed by {{currentPlanService.currentPlan.parentPlanCompanyName || "the Parent Company"}}.</strong>
                <p>
                  Contact your account administrator
                  <span [shown]="currentPlanService.currentPlan.parentPlanContactEmail">at
                    <strong>{{ currentPlanService.currentPlan.parentPlanContactEmail }}</strong>
                  </span>
                  to make changes to your account.
                </p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="settings-page-footer">
      <common-actions
        [actions]="actions"
        [saving]="saving">
      </common-actions>
    </div>
  </div>
</form>