<form
  #companyForm="ngForm"
  id="forms.companyForm"
  role="form"
  name="forms.companyForm"
  (ngSubmit)="save()"
  autocomplete="on"
  novalidate
>
  <div rvSpinner="company-settings" [rvShowSpinner]="companyService.loading" class="settings-page-container">
    <div class="company-settings settings-page-body">
      <div class="settings-page-section">
        <div id="errorBox" class="alert alert-danger" role="alert" [shown]="companyService.formError">
          <strong>{{companyService.formError}}</strong> {{companyService.apiError}}
          @if (companyService.isAddressError) {
            <suggest-general-delivery [addressObject]="companyService.company"></suggest-general-delivery>
          }
        </div>
        <div class="tw-mb-8">
          <stretchy-input
            [(ngModel)]="companyService.company.name"
            name="companyName">
          </stretchy-input>
        </div>
          <company-fields [company]="companyService.company" showManagedSections="true"
            (resetAuthKeyEvent)="resetAuthKey()"
            (resetClaimIdEvent)="resetClaimId()"
          ></company-fields>
      </div>
    </div>
    <div class="settings-page-footer">
      <common-actions
        [actions]="actions"
        [saving]="companyService.loading"
        [changeDate]="companyService.company?.changeDate"
        [changedBy]="companyService.company?.changedBy">
      </common-actions>
    </div>
  </div>
</form>
