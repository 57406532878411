<div class="tw-pb-4">
  <div rvSpinner="schedule-loader" [rvShowSpinner]="scheduleService.loadingSchedule">
    <form #scheduleForm="ngForm" id="forms.scheduleForm" role="form" name="forms.scheduleForm"
      (ngSubmit)="save()" autocomplete="on" novalidate>
      <common-actions
        [(title)]="scheduleService.schedule.name"
        [actions]="actions"
        [saving]="scheduleService.savingSchedule"
        [changeDate]="scheduleService.schedule?.changeDate"
        [changedBy]="scheduleService.schedule?.changedBy">
      </common-actions>

      <div class="tw-px-6 tw-pt-8 tw-border-t tw-border-x-0 tw-border-b-0 tw-border-solid tw-border-gray-200">
        <schedule-fields></schedule-fields>
      </div>
    </form>
  </div>
</div>
