<div>
  <div class="app-header tw-py-8 tw-px-6">
    <!-- App Title -->
    <div class="app-header-title">
      <div>
        <span [shown]="playerLicenseService.hasProfessionalLicenses()">
          {{ playerLicenseService.getUsedLicenseString() }}
        </span>
      </div>
    </div>
  </div>
  <mat-divider class="light" />
  <notification-alert
    *requireRole="'!da'"
    title="You are not a Display Administrator"
    message="Please contact your display administrator to add a new display."
  />
  <div class="tw-px-6" rvSpinner="display-loader" [rvShowSpinner]="displayService.loadingDisplay">
    <div>
      <h4>I have already installed Rise Vision.</h4>
      <div class="border-container p-4 mb-5">
        <form #displayForm="ngForm" id="forms.displayForm" role="form" name="forms.displayForm" (ngSubmit)="save()" autocomplete="on" novalidate>
          <common-actions
            [actions]="actions"
            [saving]="displayService.savingDisplay">
          </common-actions>

          <div class="grid grid-md-col-3 grid-md-gap-4 tw-pt-8 tw-border-t tw-border-x-0 tw-border-b-0 tw-border-solid tw-border-gray-200">
            <div class="grid-cell">
              <div class="form-group required" [ngClass]="{'has-error': (displayName.dirty || displayForm?.submitted) && displayName.invalid }">
                <label class="control-label" for="displayName">
                  Display Name:
                </label>
                <input #displayName="ngModel" name="displayName" id="display-name" type="text" class="form-control" [(ngModel)]="displayService.display.name" placeholder="Name your new display" required>
                <p [shown]="(displayName.dirty || displayForm?.submitted) && displayName.invalid" class="help-block validation-error-message-name text-danger">Please enter a name for your display.</p>
              </div>
            </div>
            <div class="grid-cell">
              <div class="form-group">
                <label class="control-label" for="activationKey">
                  Activation Code:
                </label>
                <input #activationKey name="activationKey" autocomplete="off" maxlength="5" type="text" class="form-control" [ngClass]="{ 'u_uppercase': displayService.display.activationKey }" [(ngModel)]="displayService.display.activationKey" (ngModelChange)="detectChanges()" placeholder="Enter the code shown on your display">
              </div>
            </div>
            <div class="grid-cell">
              <div class="form-group">
                <preview-selector [(selectedItem)]="selectedSchedule" label="Schedule:"></preview-selector>
              </div>
            </div>
          </div>
          <div id="errorBox" [shown]="displayService.apiError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
            <strong>{{displayService.errorMessage}}</strong> {{displayService.apiError}}
          </div>
        </form>
      </div>
      <h4 class="pt-3">I have not installed Rise Vision or activated my display.</h4>
      <div class="border-container mb-5">
        <div class="not-installed grid grid-md-col-3 grid-inner-borders">
          <div class="grid-cell py-4 px-5">
            <div class="flex-col space-between">
              <div>
                <h5>Use Existing Media Player</h5>
                <p class="mb-4">
                  If you already have a existing dedicated media player, get started by installing Rise Vision.
                </p>
              </div>
              <div class="grid grid-col-2 grid-xs-col-4 grid-inner-borders align-center mb-4">
                <div class="grid-cell p-3"><img alt="Windows" src="/images/install/windows.png" width="40" height="30"></div>
                <div class="grid-cell p-3"><img alt="Ubuntu" src="/images/install/ubuntu.png" width="40" height="30"></div>
                <div class="grid-cell p-3"><img alt="Amazon Signage Stick" src="/images/install/amazonsignagestick.png" width="40" height="30"></div>
                <div class="grid-cell p-3"><img alt="Chrome OS" src="/images/install/chrome.png" width="40" height="30"></div>
                <div class="grid-cell p-3"><img alt="Rasperry Pi" src="/images/install/raspberrypi.png" width="40" height="30"></div>
                <div class="grid-cell p-3"><img alt="Android" src="/images/install/android.png" width="40" height="30"></div>
                <div class="grid-cell p-3"><img alt="Apple TV" src="/images/install/appletv.png" width="40" height="30"></div>
                <div class="grid-cell p-3"><img alt="Web Player" src="/images/install/web-player.svg" width="35" height="30"></div>
              </div>
              <p class="mb-4">
                We also support IFPDs and collaboration screens.
                <a class="madero-link" target="_blank" href="https://help.risevision.com/hc/en-us/sections/115000832963-Collaboration-Displays">Learn More</a>
              </p>
              <div class="flex-row justify-end mb-3">
                <a uiSref="apps.displays.install" class="btn btn-default">Install Rise Vision</a>
              </div>
            </div>
          </div>
          <div class="grid-cell py-4 px-5 u_position-relative">
            <div class="or-label">OR</div>
            <div class="flex-col space-between">
              <div>
                <h5>Preview Virtual Display</h5>
                <p class="mb-4">
                  No Display, No Problem, preview our virtual display right here in your browser.
                </p>
              </div>
              <img class="img-fluid" alt="A classroom with a display screen showing a weather forecast." src="/images/install/classroom-preview.jpg">
              <div class="flex-row justify-end mt-4 mb-3">
                <a href="#" class="btn btn-default" (click)="showPreview()">Preview Virtual Display</a>
              </div>
            </div>
          </div>
          <div class="grid-cell py-4 px-5 u_position-relative">
            <div class="or-label">OR</div>
            <div class="flex-col space-between">
              <div>
                <h5>Purchase Rise Vision Media Player</h5>
                <p class="mb-4">
                  Purchase outright or leave the management and support to us, and purchase as a subscription hardware service plan. Now featuring
                  <a class="madero-link" targert="_blank" href="https://help.risevision.com/hc/en-us/articles/20745351943700">bulk activation</a>.
                </p>
                <div class="grid grid-col-2-1 grid-md-col-2 grid-lg-col-2-1 grid-align-center mb-3">
                  <div class="grid-cell">
                    <img class="img-fluid" alt="Rise Vision Media Player" src="/images/install/media-player.png">
                  </div>
                  <div class="grid-cell pl-4">
                    <small><em>Currently only available in the <span class="u_nowrap">United States.</span></em></small>
                  </div>
                </div>
                <p>
                  Need a display too?  Get the Avocor R Series hardware as a service (no media player required).
                  <a tabindex="0" class="madero-link u_clickable" (click)="helpWidgetFactory.showContactUs()" (keyup.enter)="helpWidgetFactory.showContactUs()">Contact Sales</a>
                </p>
                <div class="flex-row justify-end mt-4 mb-3">
                  <a href="https://help.risevision.com/hc/en-us/articles/18468794812180" target="_blank" class="btn btn-default">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
